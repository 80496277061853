/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* You can add global styles to this file, and also import other style files */
.hoverable:hover {
  background-color: lightyellow;
}

.row {
  padding-top: 8px;
}

.material-icons-large-button {
  font-size: 32px;
  cursor: pointer;
}

.material-icons-small {
  font-size: 14px;
  vertical-align: text-bottom;
}

.material-icons-small-button {
  font-size: 14px;
  vertical-align: text-bottom;
  cursor: pointer;
}
.modal-xl .modal-lg {
  max-width: 80%;
}

.mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }

@media print {
  .noprint,
  .noprint * {
    display: none !important;
  }
}

@media print {
  *, .wrapper,
  body {
    text-shadow: none !important;
    box-shadow: none !important;
    margin: 0px;
    padding: 0px !important;
    max-width: 99% !important;
    zoom: 97%;
  }

}

.xlModal .modal-dialog {
  max-width: calc(100vw - 200px);
}
